import React from 'react';

import Paper from '@mui/material/Paper';
import { Button, TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import FilesUploadPlanera from "./FilesUploadPlanera";
import FilesAprobarPlanera from "./FilesAprobarPlanera";
import Autocomplete from '@mui/material/Autocomplete';

import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';
import SnackbarMessage from '../SnackbarMessage'; // Asegúrate de proporcionar la ruta correcta al archivo SnackbarMessage

import UserService from "./services/planera.service";
import UserServiceD from "./services/dibujante.service"; // Cambiar a servicio de dibujantes

import { useState, useEffect } from 'react'
import { useParams } from 'react-router';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { useStyles, theme, direction } from '../styles'; // Importa los estilos desde el archivo styles.js

//Validacion del formulario
const validationSchema = yup.object({
    status: yup
        .string('Estado requerido')
        .required('Estado requerido'),
    subtitulo1: yup
        .string('Seleccione un area')
        //.min(8, 'Password should be of minimum 8 characters length')
        .required('Seleccione un area'),
    obra: yup
        .string('Modelo requreido ')
        //.min(8, 'Password should be of minimum 8 characters length')
        .required('Modelo requerido'),
    codigoPlano: yup
        .string()
        .required('Código plano es requerido')
        .matches(/^[A-Z0-9]{4}$/, 'Debe tener 4 caracteres alfanuméricos'),
    codigoBas: yup
        .string()
        .required('Código BAS es requerido')
        .length(5, 'Debe tener 5 dígitos'),
});

export default function PlaneraAddmod(props) {
    const [isLoading, setIsLoading] = useState(true)

    const formik = useFormik({
        initialValues: {
            archivo: '',
            status: '', // Asegúrate de que está aquí
            titulo: '',
            modelo: '',
            subtitulo1: '',
            subtitulo2: '',
            cliente: '',
            obra: '',
            linea: '',
            modelo: '',
            tipo: '',
            dibujante: '',
            nota: '',
            id: '',
            codigoBas: '', // <-- Agregar este campo
            codigoPlano: '', // <-- Agregar este campo
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            peticionPost(values);
        },
    });

    const { id, ids } = useParams();

    const styles = useStyles();
    const classes = useStyles();

    const [data, setData] = useState([]);

    const [state, setState] = useState([
        { id: 'Aprobado', value: 'Aprobado', label: 'Aprobado' },
        { id: 'En Revision', value: 'En Revision', label: 'En Revision' },
    ]);

    const [combustible, setCombustible] = useState([
        { id: 'Nafta', nombre: 'Nafta' },
        { id: 'Gas Oil', nombre: 'Gas Oil' },
        { id: 'Gnc', nombre: 'Gnc' },
    ]);

    const [tipo, setTipo] = useState([
        { id: 'C', value: 'C', label: 'Comercial' },
        { id: 'S', value: 'S', label: 'Standard' },
    ]);

    const [linea, setLinea] = useState([
        { id: 'ADS', value: 'ADS', label: 'ADS' },
        { id: 'BA', value: 'BA', label: 'BA' },
        { id: 'BE', value: 'BE', label: 'BE' },
        { id: 'BL', value: 'BL', label: 'BL' },
        { id: 'BPFM', value: 'BPFM', label: 'BPFM' },
        { id: 'BPFM', value: 'BPFM', label: 'BPFM' },
        { id: 'CEG', value: 'CEG', label: 'CEG' },
        { id: 'CL', value: 'CL', label: 'CL' },
        { id: 'CTM', value: 'CTM', label: 'CTM' },
        { id: 'CMP', value: 'CMP', label: 'CMP' },
        { id: 'DBS', value: 'DBS', label: 'DBS' },
        { id: 'DAE', value: 'DAE', label: 'DAE' },
        { id: 'DPS', value: 'DPS', label: 'DPS' },
        { id: 'Filtros', value: 'Filtros', label: 'Filtros' },
        { id: 'FL', value: 'FL', label: 'FL' },
        { id: 'GB', value: 'GB', label: 'GB' },
        { id: 'GP-GPA', value: 'GP-GPA', label: 'GP-GPA' },
        { id: 'GP', value: 'GP', label: 'GP' },
        { id: 'GVT', value: 'GVT', label: 'GVT' },
        { id: 'HL', value: 'HL', label: 'HL' },
        { id: 'IN', value: 'IN', label: 'IN' },
        { id: 'Passtru', value: 'Passtru', label: 'Passtru' },
        { id: 'TM', value: 'TM', label: 'TM' },
        { id: 'UTA', value: 'UTA', label: 'UTA' },
        { id: 'MFU', value: 'MFU', label: 'MFU' },
        { id: 'MH', value: 'MH', label: 'MH' },
        { id: 'MT', value: 'MT', label: 'MT' },
        { id: 'MR', value: 'MR', label: 'MR' },
        { id: 'LVA', value: 'LVA', label: 'LVA' },
        { id: 'TNR', value: 'TNR', label: 'TNR' },
        { id: 'TNR', value: 'TNR', label: 'TNR' },
        { id: 'TK', value: 'TK', label: 'TK' },
        { id: 'VE', value: 'VE', label: 'VE' },
        { id: 'VL', value: 'VL', label: 'VL' },
        { id: 'VM', value: 'VM', label: 'VM' },
        { id: 'VM', value: 'VM', label: 'VM' },
        { id: 'ZF', value: 'ZF', label: 'ZF' },
    ]);

    const [dibujantes, setDibujantes] = useState([]);

    const [version, setVersion] = useState('01'); // Versión inicial

    const peticionPost = async (values) => {
        const response = await UserService.addmodPlano(id, values);
        cerrarEditar()
    }

    const cerrarEditar = () => {
        props.history.push(process.env.PUBLIC_URL + "/planera");
    }

    useEffect(() => {
        const tipoCodigo = formik.values.tipo === 'S' ? '01' : '02';
        const versionCodigo = version.padStart(2, '0');
        const nuevoTitulo = `${tipoCodigo}${formik.values.codigoBas || '00000'}${(formik.values.codigoPlano || '').padEnd(4, '0').substring(0, 4)}${versionCodigo}`;
        formik.setFieldValue('titulo', nuevoTitulo);
    }, [formik.values.tipo, formik.values.codigoBas, formik.values.codigoPlano, version]);

    useEffect(() => {
        const GetData = async () => {
            try {
                setIsLoading(true);
                const response = await UserService.getPlano(id);
                console.log("plano", response);
                if (response) {
                    // 1. Establecemos todos los valores de la API normalmente
                    formik.setValues(response);

                    // 2. Si existe un título, parseamos sus componentes
                    if (response.titulo) {
                        const tipoFromCode = response.titulo.startsWith('01') ? 'S' : 'C'; // Los primeros 2 dígitos
                        const codigoBasValue = response.titulo.substring(2, 7); // Siguientes 5 dígitos
                        const codigoPlanoValue = response.titulo.substring(7, 11); // Siguientes 4 dígitos
                        const versionValue = response.titulo.substring(11, 13); // Últimos 2 dígitos

                        // 3. Actualizamos los campos del formulario
                        formik.setValues({
                            ...response, // Mantenemos todos los valores originales
                            tipo: tipoFromCode, // Sobrescribimos el tipo
                            codigoBas: codigoBasValue, // Agregamos el código BAS parseado
                            codigoPlano: codigoPlanoValue // Agregamos el código plano parseado
                        });

                        // 4. Establecemos la versión en el estado local
                        setVersion(versionValue);
                    }

                    setIsLoading(false);
                }
            } catch (e) {
                //... manejo de errores
            }
        }
        GetData();
    }, []);


    useEffect(() => {
        const GetData = async () => {
            try {
                setIsLoading(true);
                const result = await UserServiceD.getDibujantes(); // Asegúrate de que UserServiceD es el servicio correcto para dibujantes
                console.log("dibu", result);
                if (result && result.data) {
                    setDibujantes(result.data); // Guardamos los datos de los dibujantes
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } catch (e) {
                setIsLoading(false);
                handleShowMessage('500');
            }
        }
        GetData();
    }, []);

    const inicio = () => {
        props.history.push(process.env.PUBLIC_URL + "/planera")
    }

    //Para el uso del componente de mensajes 
    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const handleShowMessage = (message) => {
        setErrorMessage(message);
        setOpen(true);
    };
    const handleCloseMessage = () => {
        setOpen(false);
    };
    //Fin

    return (
        <Paper className={classes.root}>

            <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
                <Toolbar>
                    {isLoading && <CircularProgress color="secondary" />}

                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
                    </IconButton>
                    <Typography variant="h6" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
                        Agregar/Editar Plano
                    </Typography>
                </Toolbar>
            </AppBar>
            <br />

            <form onSubmit={formik.handleSubmit}>

                <Grid container spacing={3}>

                    <Grid item xs={12}>
                        <Autocomplete
                            className={styles.inputMaterial}
                            options={state}
                            value={state.find((option) => option.id === formik.values.status) || null}
                            getOptionLabel={(option) => `${option.label}`}
                            onChange={(event, selectedOption) => {
                                formik.setFieldValue('status', selectedOption ? selectedOption.id : '');
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    className={styles.inputMaterial}
                                    label="Estado"
                                    name="status"
                                    error={Boolean(formik.touched.status && formik.errors.status)}
                                    helperText={formik.touched.status && formik.errors.status}
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid>

                    {/* Después del campo de estado */}
                    <Grid item xs={2}>
                        <Autocomplete
                            className={styles.inputMaterial}
                            options={tipo}
                            value={tipo.find((option) => option.id === formik.values.tipo) || null}
                            getOptionLabel={(option) => `${option.label}`}
                            onChange={(event, selectedOption) => {
                                formik.setFieldValue('tipo', selectedOption ? selectedOption.id : '');
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    className={styles.inputMaterial}
                                    label="Tipo"
                                    name="tipo"
                                    error={Boolean(formik.touched.tipo && formik.errors.tipo)}
                                    helperText={formik.touched.tipo && formik.errors.tipo}
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <TextField
                            name="codigoBas"
                            label="Código BAS"
                            value={formik.values.codigoBas || ''}
                            onChange={(e) => {
                                const newValue = e.target.value.slice(0, 5); // Limitar a 5 caracteres para código BAS
                                formik.handleChange(e);
                                formik.setFieldValue('codigoBas', newValue);
                            }}
                            onBlur={formik.handleBlur}
                            error={Boolean(formik.touched.codigoBas && formik.errors.codigoBas)}
                            helperText={formik.touched.codigoBas && formik.errors.codigoBas}
                            inputProps={{ maxLength: 5 }} // Esto limita la cantidad de caracteres a 5
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <TextField
                            name="codigoPlano"
                            label="Código Plano"
                            value={formik.values.codigoPlano}
                            onChange={(e) => {
                                formik.handleChange(e);
                                formik.setFieldValue('codigoPlano', e.target.value.slice(0, 4));
                            }}
                            onBlur={formik.handleBlur}
                            error={Boolean(formik.touched.codigoPlano && formik.errors.codigoPlano)}
                            helperText={formik.touched.codigoPlano && formik.errors.codigoPlano}
                            inputProps={{ maxLength: 4 }}
                        />

                    </Grid>

                    <Grid item xs={2}>
                        <Autocomplete
                            options={Array.from({ length: 50 }, (_, i) => (i + 1).toString().padStart(2, '0'))}
                            value={version}
                            onChange={(e, value) => setVersion(value || '01')}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Versión"
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <Autocomplete
                            className={styles.inputMaterial}
                            options={dibujantes}
                            value={dibujantes.find((option) => option.id === formik.values.dibujante) || null}
                            getOptionLabel={(option) => `${option.nombre}`} // Cambiamos a 'nombre' ya que es lo que viene en la respuesta de la API
                            onChange={(event, selectedOption) => {
                                formik.setFieldValue('dibujante', selectedOption ? selectedOption.id : '');
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    className={styles.inputMaterial}
                                    label="Dibujante"
                                    name="dibujante"
                                    error={Boolean(formik.touched.dibujante && formik.errors.dibujante)}
                                    helperText={formik.touched.dibujante && formik.errors.dibujante}
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            name="titulo"
                            className={styles.inputMaterial}
                            label="Código"
                            value={formik.values.titulo}
                            InputProps={{ readOnly: true }}
                            variant="outlined"
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            name="subtitulo1"
                            className={styles.inputMaterial}
                            label="Subtitulo1"
                            value={formik.values.subtitulo1}
                            onChange={formik.handleChange}
                            error={formik.touched.subtitulo1 && Boolean(formik.errors.subtitulo1)}
                            helperText={formik.touched.subtitulo1 && formik.errors.subtitulo1}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            name="subtitulo2"
                            className={styles.inputMaterial}
                            label="Subtitulo2"
                            value={formik.values.subtitulo2}
                            onChange={formik.handleChange}
                            error={formik.touched.subtitulo2 && Boolean(formik.errors.subtitulo2)}
                            helperText={formik.touched.subtitulo2 && formik.errors.subtitulo2}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            name="cliente"
                            className={styles.inputMaterial}
                            label="Cliente"
                            value={formik.values.cliente}
                            onChange={formik.handleChange}
                            error={formik.touched.cliente && Boolean(formik.errors.cliente)}
                            helperText={formik.touched.cliente && formik.errors.cliente}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            name="obra"
                            className={styles.inputMaterial}
                            label="Obra"
                            value={formik.values.obra}
                            onChange={formik.handleChange}
                            error={formik.touched.obra && Boolean(formik.errors.obra)}
                            helperText={formik.touched.obra && formik.errors.obra}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            name="modelo"
                            className={styles.inputMaterial}
                            label="Modelo"
                            value={formik.values.modelo}
                            onChange={formik.handleChange}
                            error={formik.touched.modelo && Boolean(formik.errors.modelo)}
                            helperText={formik.touched.modelo && formik.errors.modelo}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <Autocomplete
                            className={styles.inputMaterial}
                            options={linea}
                            value={linea.find((option) => option.id === formik.values.linea) || null}
                            getOptionLabel={(option) => `${option.label}`}
                            onChange={(event, selectedOption) => {
                                formik.setFieldValue('linea', selectedOption ? selectedOption.id : '');
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    className={styles.inputMaterial}
                                    label="Linea"
                                    name="linea"
                                    error={Boolean(formik.touched.linea && formik.errors.linea)}
                                    helperText={formik.touched.linea && formik.errors.linea}
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            name="nota"
                            className={styles.inputMaterial}
                            label="Nota"
                            value={formik.values.nota}
                            onChange={formik.handleChange}
                            error={formik.touched.nota && Boolean(formik.errors.nota)}
                            helperText={formik.touched.nota && formik.errors.nota}
                        />
                    </Grid>

                    <SnackbarMessage
                        open={open}
                        message={errorMessage}
                        severity="success"
                        onClose={handleCloseMessage}
                    />

                </Grid>
                <br /><br />
                <div align="right">
                    <Button color="primary" type="submit">Enviar</Button>
                    <Button onClick={() => cerrarEditar()}>Cancelar</Button>
                </div>

            </form>

            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <FilesUploadPlanera id={id} documento={"Planos"} />
                </Grid>
                <Grid item xs={6}>
                    <FilesAprobarPlanera id={id} ids={ids} documento={"Tickets"} />
                </Grid>
            </Grid>

        </Paper>
    );
}