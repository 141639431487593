import React, { useEffect, useState, useRef } from "react";

import { makeStyles, useTheme } from '@mui/styles';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import fondo from '../../fondo.jpg';
import logo from '../../google.png';
import SnackbarMessage from '../SnackbarMessage'; // Asegúrate de proporcionar la ruta correcta al archivo SnackbarMessage

import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDhIHuxwerABnxQws313Fh0gy-WWyrIvuA",
  authDomain: "intranetapp-4bc3b.firebaseapp.com",
  projectId: "intranetapp-4bc3b",
  storageBucket: "intranetapp-4bc3b.appspot.com",
  messagingSenderId: "271144301026",
  appId: "1:271144301026:web:3149fac402f7c6864f89bb",
  measurementId: "G-4XJ12MTFPJ"
};

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://intranet.devcas.ar/">
        Intranet
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(' + fondo + ')',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#fff',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: (8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: (1),
    backgroundColor: '#fff',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: 1,
  },
  submit: {
    margin: (3, 0, 2),
  },
  buttonContainer: {
    marginTop: 120, // Puedes ajustar este valor según tus necesidades
  },
}));

const Login = (props) => {
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app); // Get the auth instance

  const classes = useStyles();
  const [user, setUser] = useState(null); // Nuevo estado para almacenar la información del usuario

  const [message, setMessage] = useState("");

  // Nuevo estado para almacenar el saludo
  const [greeting, setGreeting] = useState("");

  //Para el uso del componente de mensajes 
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  
  const handleShowMessage = (message) => {
    setErrorMessage(message);
    setOpen(true);
  };
  
  const handleCloseMessage = () => {
    setOpen(false);
  };
  //Fin

  const signIn = async () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const user = result.user;

        // Verifica el dominio del correo electrónico permitido
        if (user.email.endsWith('@casiba.ar') || user.email === 'scs.1975@gmail.com' || user.email === 'sergio.sam.facebook@gmail.com' || user.email === 'ventas.casiba@gmail.com') {
          setUser(user);
          console.log(result);
          localStorage.setItem('user3', JSON.stringify({
            email: user.email,
          }
            //result.user
          ));

          // Puedes almacenar el token de acceso por separado si lo necesitas
          const accessToken = await user.getIdToken();
          localStorage.setItem('accessToken', accessToken);

          props.history.push("/home");
          window.location.reload();
        } else {
          // Mostrar un mensaje de no habilitado y redirigir al inicio de sesión
          handleShowMessage('Acceso no habilitado para este correo electrónico');
          signOut(); // Cerrar sesión inmediatamente
        }

      })
      .catch((error) => {
        // Handle errors here
        console.error(error);
      });
  };

  const signOut = () => {
    auth.signOut();
    setUser(null);
  };

  // ...

  useEffect(() => {
    // Obtiene la hora actual
    const currentHour = new Date().getHours();

    // Decide el saludo según la hora
    if (currentHour >= 5 && currentHour < 12) {
      setGreeting("Buenos días");
    } else if (currentHour >= 12 && currentHour < 18) {
      setGreeting("Buenas tardes");
    } else {
      setGreeting("Buenas noches");
    }

  }, []);

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={6} className={classes.image} />
      <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {greeting}
          </Typography>
          {message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            </div>
          )}

          <div className={classes.buttonContainer}>
            {/* Botón de inicio de sesión con Google */}
            {!user && (
              <Button
                variant="contained"
                color="primary"
                style={{ color: 'black', backgroundColor: 'white' }}
                onClick={signIn}
              >
                <img
                  src={logo}
                  alt="Google Icon"
                  style={{ width: '20px', height: '20px', marginRight: '10px' }}
                />
                Iniciar sesión con Gmail
              </Button>
            )}

            {/* Botón de cierre de sesión */}
            {user && (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={signOut}
                  style={{ color: 'black', backgroundColor: 'white' }}
                >
                  Cerrar sesión
                </Button>
                <h3>Usuario autenticado: {user.displayName}</h3>
              </>
            )}
          </div>

        </div>

      </Grid>

      <SnackbarMessage
        open={open}
        message={errorMessage}
        severity="success"
        onClose={handleCloseMessage}
      />

    </Grid>
  );
};

export default Login;

