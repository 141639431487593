import authHeader from "../../../services/auth-header";
import { handleResponse } from '../../../services/handle-response';
import http from "../../../services/http-common";

const API_URL = 'https://devapi.casiba.ar/api/v1/';
const API_URL_MITRE = 'https://api.casiba.net/api/v1/';

const user = JSON.parse(localStorage.getItem('user3'));

const upload = (id, file, document, onUploadProgress) => {
  let data = new FormData();
  data.append("images", file);
  data.append('email', user.email);
  data.append('document', document);
  data.append('id', id);

  return http.post("/uploadfiles_planera", data, { headers: authHeader(), onUploadProgress, });
};

//trae datos de un plano
const getPlano = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_plano', requestOptions).then(handleResponse));
};

//trae los tickets de un plano 
const getPlanoTickets = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_plano_tickets', requestOptions).then(handleResponse));
};

//trae lista de planos
const getPlanos = () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_planos', requestOptions).then(handleResponse));
};

//agreda o modifica un plano
const addmodPlano = (id, row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  console.log("ros",row);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'addmodplano', requestOptions).then(handleResponse));
};

const aprobarPlano = (file, row, idplano, idticket) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('file', file);
  data.append('idplano', idplano);
  data.append('idticket', idticket);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'aprobar_plano', requestOptions).then(handleResponse));
};

const activarPlano = (idplano, documento, row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('idplano', idplano);
  data.append('documento', documento);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'activar_plano', requestOptions).then(handleResponse));
};

//elimina un plano
const delPlano = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'delplano', requestOptions).then(handleResponse));
};

//trae lista de los archivos adjuntos de planera
const getFilesPlanera = (id, documento) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('document', documento);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'getfiles_planera', requestOptions).then(handleResponse));
};

//elimina un archivo
const delImagePlanera = (id, documento, key) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('key', key);
  data.append('document', documento);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'delimage_planera', requestOptions).then(handleResponse));
};

export default {
  upload,
  getPlano,
  aprobarPlano,
  activarPlano,
  getPlanoTickets,
  getPlanos,
  addmodPlano,
  delPlano,
  getFilesPlanera,
  delImagePlanera,
};
