import React from 'react';

import Paper from '@mui/material/Paper';
import { Button, TextField } from '@mui/material';

import { AppBar, CircularProgress, IconButton, Toolbar, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import UserService from "./services/dibujante.service"; // Cambiar a servicio de dibujantes
import SnackbarMessage from '../SnackbarMessage'; // Asegúrate de proporcionar la ruta correcta al archivo SnackbarMessage

import { useState, useEffect } from 'react'
import { useParams } from 'react-router';

import { useFormik } from 'formik';
import * as yup from 'yup';

import { useStyles, theme, direction } from '../styles'; // Importa los estilos desde el archivo styles.js

//Validacion del formulario
const validationSchema = yup.object({
    codigo: yup
        .string('Código requerido')
        .required('Código requerido'),
    nombre: yup
        .string('Nombre requerido')
        .required('Nombre requerido'),
});

export default function DibujantesAddMod(props) {
    const [isLoading, setIsLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            codigo: '',
            nombre: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            peticionPost(values);
        },
    });

    const { id } = useParams();

    const styles = useStyles();
    const classes = useStyles();

    const peticionPost = async (values) => {
        const response = await UserService.addmodDibujante(id, values); // Ajustar método según tu servicio
        cerrarEditar();
    };

    const cerrarEditar = () => {
        props.history.push(process.env.PUBLIC_URL + "/dibujantes"); // Cambiar ruta
    };

    useEffect(() => {
        if (id) {
            const GetData = async () => {
                try {
                    setIsLoading(true);
                    const response = await UserService.getDibujante(id); // Cambiar método según tu servicio
                    if (response) {
                        formik.setValues(response);
                        setIsLoading(false);
                    } else {
                        setIsLoading(false);
                    }
                } catch (e) {
                    console.log(e);
                    setIsLoading(false);
                    handleShowMessage('500');
                }
            };
            GetData();
        }
    }, [id]);

    const inicio = () => {
        props.history.push(process.env.PUBLIC_URL + "/dibujantes"); // Cambiar ruta
    };

    //Para el uso del componente de mensajes 
    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const handleShowMessage = (message) => {
        setErrorMessage(message);
        setOpen(true);
    };
    const handleCloseMessage = () => {
        setOpen(false);
    };
    //Fin

    return (
        <Paper className={classes.root}>

            <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
                <Toolbar>
                    {isLoading && <CircularProgress color="secondary" />}

                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
                    </IconButton>
                    <Typography variant="h5" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
                        Agregar/Modificar Dibujante
                    </Typography>
                </Toolbar>
            </AppBar>
            <br />

            <form onSubmit={formik.handleSubmit}>

                <div className={styles.inputMaterial}>
                    <TextField
                        name="codigo"
                        label="Código"
                        value={formik.values.codigo}
                        onChange={formik.handleChange}
                        error={formik.touched.codigo && Boolean(formik.errors.codigo)}
                        helperText={formik.touched.codigo && formik.errors.codigo}
                    />
                </div>

                <div className={styles.inputMaterial}>
                    <TextField
                        name="nombre"
                        label="Nombre"
                        value={formik.values.nombre}
                        onChange={formik.handleChange}
                        error={formik.touched.nombre && Boolean(formik.errors.nombre)}
                        helperText={formik.touched.nombre && formik.errors.nombre}
                    />
                </div>

                <SnackbarMessage
                    open={open}
                    message={errorMessage}
                    severity="success"
                    onClose={handleCloseMessage}
                />

                <br /><br />
                <div align="right">
                    <Button color="primary" type="submit">Enviar</Button>
                    <Button onClick={() => cerrarEditar()}>Cancelar</Button>
                </div>
            </form>
        </Paper>
    );
}