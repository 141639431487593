import React from 'react';

import { withStyles, makeStyles, useTheme } from '@mui/styles';

import Paper from '@mui/material/Paper';
import { Button, TextField } from '@mui/material';
import Link from '@mui/material/Link';
import { Grid, InputLabel, Select, MenuItem } from '@mui/material';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';

import UserService from "../../services/user.service";
import { useState, useEffect } from 'react'
import { useParams } from 'react-router';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useStyles, theme, direction } from '../styles'; // Importa los estilos desde el archivo styles.js
import SnackbarMessage from '../SnackbarMessage'; // Asegúrate de proporcionar la ruta correcta al archivo SnackbarMessage

//Validacion del formulario
const validationSchema = yup.object({
  nombre: yup
    .string('Nombre del usurio requerido')
    .required('Nombre del usuario requerido'),
  email: yup
    .string('Email del usuario requerido')
    .email('Emeil del usuario requerido')
    .required('Email del usuario requerido'),
});

export default function Usuariosmod(props) {
  const [isLoading, setIsLoading] = useState(false)

  //inicializacion de variables y validacion
  const formik = useFormik({
    initialValues: {
      nombre: '',
      username: '',
      email: '',
      password: '',
      agente: '',
      empresa: '',
      idsector: '',
      roles: [], // Inicialmente, no hay roles seleccionados
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      peticionPost(values);
      //alert(JSON.stringify(values, null, 2));
    },
  });

  const { id } = useParams();

  const styles = useStyles();
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [sectores, setSectores] = useState([]);

  const [dataempresas, setDataEmpresas] = useState([]);

  const [roles] = useState([
    { id: 'ROLE_MASTER', nombre: 'Master' },
    { id: 'ROLE_OWNER', nombre: 'Dueño' },
    { id: 'ROLE_ADMIN', nombre: 'Administrador' },
    { id: 'ROLE_ADMINISTRADORES', nombre: 'Administradores' },
    { id: 'ROLE_USER', nombre: 'Usuario' },
  ]);

  const peticionPost = async (values) => {
    const response = await UserService.addmodUsuario(id, values);
    setData(data.concat(response.data))
    cerrarEditar()
  }

  const cerrarEditar = () => {
    props.history.push(process.env.PUBLIC_URL + "/usuarios");
  }

  useEffect(() => {
    if (id) {
      const GetData = async () => {
        try {
          const response = await UserService.getUsuario(id);
          if (response) {
            formik.setValues(response);
          } else {
            //props.history.push(process.env.PUBLIC_URL +"/login");
          }
        } catch (e) {
          handleShowMessage('500');
        }
      };
      GetData();
    }
  }, [id]);

  useEffect(() => {
    const GetData = async () => {
      try {
        const response = await UserService.getSectores();
        console.log(response); // Asegúrate de que la respuesta es como esperas
        if (response) {
          setSectores(response);
        } else {
          // Manejo de caso donde la respuesta es inesperada
          setSectores([]);
          handleShowMessage('Error al obtener sectores');
        }
      } catch (e) {
        console.error('Error al obtener sectores:', e);
        handleShowMessage('500');
      }
    };
    GetData();
  }, []);

  const inicio = () => {
    props.history.push(process.env.PUBLIC_URL + "/usuarios")
  }

  //Para el uso del componente de mensajes 
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const handleShowMessage = (message) => {
    setErrorMessage(message);
    setOpen(true);
  };
  const handleCloseMessage = () => {
    setOpen(false);
  };
  //Fin

  return (
    <Paper className={classes.root}>

      <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
        <Toolbar>
          {isLoading && <CircularProgress color="secondary" />}

          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
          </IconButton>
          <Typography variant="h5" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
            Usuarios
          </Typography>
        </Toolbar>
      </AppBar>
      <br />

      <form onSubmit={formik.handleSubmit}>
        <h3>Editar Usuario</h3>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              name="nombre"
              className={styles.inputMaterial}
              label="Nombre"
              autoFocus={true}
              required
              value={formik.values.nombre}
              onChange={formik.handleChange}
              error={formik.touched.nombre && Boolean(formik.errors.nombre)}
              helperText={formik.touched.nombre && formik.errors.nombre}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="email"
              className={styles.inputMaterial}
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="password"
              className={styles.inputMaterial}
              label="Password"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="agente"
              className={styles.inputMaterial}
              label="Agente"
              value={formik.values.agente}
              onChange={formik.handleChange}
              error={formik.touched.agente && Boolean(formik.errors.agente)}
              helperText={formik.touched.agente && formik.errors.agente}
            />
          </Grid>

          <Grid item xs={6}>
            <InputLabel htmlFor="outlined-roles">Rol</InputLabel>
            <Select
              className={styles.inputMaterial}
              multiple // Agregamos la prop multiple para habilitar el Select múltiple
              value={formik.values.roles}
              onChange={(event) => {
                const selectedRoles = event.target.value;
                formik.setFieldValue('roles', selectedRoles);
              }}
              error={formik.touched.roles && Boolean(formik.errors.roles)}
              label="Roles"
              inputProps={{
                name: 'roles',
                id: 'outlined-roles',
              }}
            >
              {roles.map((value) => (
                <MenuItem key={value.id} value={value.id}>
                  {value.nombre}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item xs={6}>
            <InputLabel htmlFor="outlined-roles">Sector</InputLabel>
            <Select
              className={styles.inputMaterial}
              value={formik.values.idsector}
              onChange={(event) => {
                const selectedSector = event.target.value;
                formik.setFieldValue('idsector', selectedSector);
              }}
              error={formik.touched.idsector && Boolean(formik.errors.idsector)}
              label="Sector"
              inputProps={{
                name: 'idsector',
                id: 'outlined-roles',
              }}
            >
              {sectores.map((value) => (
                <MenuItem key={value.id} value={value.id}>
                  {value.nombre}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <SnackbarMessage
            open={open}
            message={errorMessage}
            severity="success"
            onClose={handleCloseMessage}
          />

        </Grid>
        <br /><br />
        <div align="right">
          <Button color="primary" type="submit">Editar</Button>
          <Button onClick={() => cerrarEditar()}>Cancelar</Button>
        </div>
      </form>
    </Paper>
  );
}