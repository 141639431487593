import authHeader from "../../../services/auth-header";
import { handleResponse } from '../../../services/handle-response';

const API_URL = 'https://devapi.casiba.ar/api/v1/';

const user = JSON.parse(localStorage.getItem('user3'));

//trae datos de un dibujante
const getDibujante = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_dibujante', requestOptions).then(handleResponse));
};

//trae lista de dibujantes
const getDibujantes = () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_dibujantes', requestOptions).then(handleResponse));
};

//agreda o modifica un dibujente
const addmodDibujante = (id, row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  console.log("ros",row);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'addmod_dibujante', requestOptions).then(handleResponse));
};

//elimina un dibujante
const delDibujante = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'del_dibujante', requestOptions).then(handleResponse));
};

export default {
  getDibujante,
  getDibujantes,
  addmodDibujante,
  delDibujante,
};
